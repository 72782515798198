<template>
  <div class="background-img-4 flx flx-d-column fx-center h-100">
    <div class="title">
      <h2 class="step">2º PASSO</h2>
      <h2 class>ESCOLHA SUA FOTO</h2>
    </div>
    <div class="fx-center flx-d-column">
      <label for="inputFile">
        <div class="fx-center">
          <img
            :src="loadedFile ? loadedFile : require('../../assets/img/img.png')"
            alt="upload"
            width="45%"
          />
        </div>
      </label>
      <input type="file" id="inputFile" ref="inputFile" style="display: none;" />
      <div class="fx-center" v-show="fileObject">
        <button class="btn-yellow" type="button" @click="sendAvatar">Enviar</button>
      </div>
    </div>
    <div class="mancha-step">
      <img src="../../assets/img/element-2.png" alt="imagem" />
    </div>
  </div>
</template>

<style>
</style>

<script>
import tusSender from "../../services/tusSender";
import router from "../../router";
import store from "../../store";
import axios from "axios";
import { apiBaseUrl } from "../../services/api";
import headerBuilder from "../../services/headerBuilder";
import LoaderMixin from "../../mixins/LoaderMixin";

export default {
  name: "PhotoSubmit",
  mixins: [LoaderMixin()],
  data() {
    return {
      fileObject: null,
      loadedFile: null,
      absoluteModifier: function () {},
    };
  },
  created() {
    if (store.state.audioNugget && store.state.audioNugget.avatarReference) {
      // Step is already set, skip it
      router.push({
        name: "InstagramSignature",
      });
      return;
    }
  },
  mounted() {
    this.$refs.inputFile.addEventListener("change", (e) => {
      this.fileObject = e.target.files[0];
      this.loadedFile = null;
      var fileReader = new FileReader();
      fileReader.onload = (er) => {
        this.loadedFile = er.target.result;
      };
      fileReader.readAsDataURL(this.fileObject);
    });
  },
  methods: {
    async sendAvatar() {
      const { proms, absoluteModifier } = this.loaderify(
        async () => {
          const upload = await tusSender.createUpload(
            this.fileObject,
            this.onUploadProgress,
            this.doWithUpload
          );
          console.log("Download %s from %s", upload.file.name, upload.url);
          const tusId = upload.url.match(/tus\/(.*)/)[1];
          const managedMediaResponse = await axios.post(
            `${apiBaseUrl}/api/ManagedMedia/CreateManagedMedia`,
            {
              FileReference: tusId,
              MediaName: "Avatar - " + Math.ceil(Math.random() * 100 + 1),
            },
            {
              headers: headerBuilder.build(),
            }
          );
          const mediaReference = managedMediaResponse.data.mediaReference;
          console.log("Media Reference for Avatar Nugget: ", mediaReference);
          store.commit("mergeAudioNugget", {
            avatarReference: mediaReference,
          });
          router.push({
            name: "InstagramSignature",
          });
        },
        this.fileObject.size,
        true
      );
      this.absoluteModifier = absoluteModifier;
      await proms;
      this.absoluteModifier(0);
    },
    async onUploadProgress(upload, bytesUploaded, bytesTotal) {
      console.log(bytesUploaded, "of", bytesTotal);
      this.absoluteModifier(1000 + bytesTotal - bytesUploaded);
    },
    async doWithUpload(upload) {
      upload.start();
    },
  },
};
</script>
